import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavBar from '../components/NavBar';
import Contact from '../components/Contact';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '../components/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faHome,
} from '@fortawesome/free-solid-svg-icons';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.background.default,
    textAlign: 'center',
    fontWeight: 500,
    background: theme.palette.secondary.main,
    backgroundImage: `repeating-linear-gradient(135deg, ${theme.palette.secondary.dark} 0, ${theme.palette.secondary.dark} 1px, transparent 1px, transparent 30px)`,
    zIndex: -10,
    marginBottom: '20vh',
  },
  border: {
    width: 620,
    padding: 10,
    borderBottom: '1px solid #bf563d',
  },
  root: {
    flexGrow: 1,
    margin: 75,
  },
  titleBorder: {
    borderLeft: '1px solid #bf563d',
    paddingLeft: 10,
  },
  subtitleBorder: {
    borderBottom: '1px solid #bf563d',
    paddingBottom: 10,
  },
  stripes: {
    color: theme.palette.background.default,
    paddingTop: theme.spacing(2),
    margin: 0,
    paddingBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(to bottom right, #FFF 50%, ${theme.palette.background.default} 50%)`,
    zIndex: -10,
    marginBottom: theme.spacing(1),
  },
  stripesLeft: {
    padding: 0,
    margin: 0,
    height: '25vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transform: 'skewY(5deg)',
  },
  stripesRight: {
    padding: 0,
    margin: 0,
    height: '15vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transform: 'skewY(-5deg)',
  },

  media: {
    display: 'block',
    position: 'absolute',
    bottom: '-40%',
    // zIndex: 10,
    left: 0,
    right: 0,
  },
  marginRight: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  align: {
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  link: {
    color: '#1f3536',
    textDecoration: 'none',
  },
  projectNum: {
    paddingTop: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    fontKerning: 5,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
}));

export default function Weatherd() {
  const data = useStaticQuery(graphql`
    query {
      weatherd: file(relativePath: { eq: "Weatherd.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xl">
        <Box my={5}>
          <NavBar />
        </Box>
      </Container>
      <Box
        position="relative"
        m={0}
        p={0}
        maxWidth="100vw"
        maxHeight="75vh"
        className={classes.stripes}
      >
        <Box
          display="flex"
          justifyContent="space-evenly"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography variant="h1" className={classes.projectNum}>
              WEATHER'D
            </Typography>
          </Box>
          <Container maxWidth="md">
            <Img
              alt="weather'd website screenshot"
              fluid={data.weatherd.childImageSharp.fluid}
            />
          </Container>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={6} justify="center" direction="row">
          <Grid item xs={12} md={4}>
            <Typography variant="h3" className={classes.align}>
              Overview
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} className={classes.titleBorder}>
            <Typography variant="h5" gutterBottom>
              Since graduating Fullstack, I've been attending Mintbean 4-hour
              Hackathons to keep consistent practice in coding. I created
              Weather'd during one of these events. The only parameters we were
              given was to use a public weather API to source our data. After
              comparing a few APIs, I thought OpenWeatherMap was the best
              choice, due to its easy-to-follow documentation and a free usage
              plan. This was my first time working with a public API instead of
              creating my own RESTful APIs and database models.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Through this project, I learned the importance of prioritization
              and time management. I wanted to challenge myself to build a
              responsive CSS from scratch and boy, did that eat up a lot of
              time. With most of my time spent on styling, I was left with an
              underwhelming amount of information being sent to my app so I knew
              I wanted to continue working on it. I gathered feedback from
              friends and gained insight to issues I overlooked during coding.
              This led to user experience improvements like city/state
              autocomplete suggestions, a cleaned-up user interface, more
              weather data and animations from GSAP.
            </Typography>
            <Typography variant="h5" gutterBottom>
              While I know weather apps are not a novel idea, I'm really proud
              of what I accomplished in the span of a couple hours and beyond.
              For future enhancements, I'm going to add hourly weather
              information and have the background update based on the time day
              or weather conditions.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.stripesLeft}></Box>
      <Box className={classes.root}>
        <Grid container spacing={6} justify="center" direction="row">
          <Grid item xs={12} md={4}>
            <Typography variant="h3" className={classes.align}>
              Tech Stack
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} className={classes.titleBorder}>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Language & Environment
              </Typography>
              <Typography variant="h5" gutterBottom>
                JavaScript
              </Typography>
              <Typography variant="h5" gutterBottom>
                Node.js
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Backend
              </Typography>
              <Typography variant="h5" gutterBottom>
                Data pulled from public APIs: OpenWeatherMap and Mapbox
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Frontend
              </Typography>
              <Typography variant="h5" gutterBottom>
                React.js - written with React Hooks
              </Typography>
              <Typography variant="h5" gutterBottom>
                Custom Responsive CSS
              </Typography>
              <Typography variant="h5" gutterBottom>
                GSAP
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" p={2}>
          <Button
            className={classes.marginRight}
            variant="contained"
            color="secondary"
            size="large"
            href="http://jennmez.github.io/weather"
            target="blank"
          >
            DEMO
          </Button>
          <Button
            className={classes.marginLeft}
            variant="outlined"
            size="large"
            color="secondary"
            href="https://github.com/jennmez/weather"
            target="blank"
          >
            GITHUB
          </Button>
        </Box>
      </Container>
      <Box className={classes.stripesRight}></Box>
      <Container maxWidth="lg">
        <Box mt={5}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}>
                <Link to="/opus" color="secondary">
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                  <Box component="span" p={2} className={classes.link}>
                    Opus
                  </Box>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}>
                <Link to="/" color="secondary">
                  <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}>
                <Link to="/pickles" color="secondary">
                  <Box component="span" p={2} className={classes.link}>
                    Graced Pickles
                  </Box>
                  <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={5}>
        <Contact />
      </Box>
    </>
  );
}
